import { BaseEntityModel } from '@common-src/model/base-model';
import { FormControl, FormControlType, FormControlTextModel, FormControlOptionModel } from '@common-src/model/form-control';
import { QueryPageModel } from '@common-src/model/query-model';
import { QueryControl, QueryControlType } from '@common-src/model/query-control';
import { dateFormat, dateFromNow } from '@common-src/filter';
import { DEFAULT_FORMAT } from '@common-src/filter/date-format';
import moment, { Moment } from 'moment';
import { getEndTimeByIntervalType } from '@common-src/utils/time_uitil';
import { PeriodTypeEnum } from '@common-src/model/enum';
import { FilterFileType } from '@common-src/filter/engine-template-type';

export class EngineTemplateModel extends BaseEntityModel {
    static baseUrl = `${REPORT_EBGINE_REQUEST_PATH}/report/template`;
    templateName: string = undefined;
    modelStatus: number = undefined;
    enabled: boolean = undefined;
    resourceList: Array<any> = null;
    templateUrl: string = undefined;
    tenantId: string = undefined;
    updateUser: string = undefined;
    description: string = '';
    checked: boolean = false;
    createUser: string = undefined;
    updateTime: string = undefined;
    updatedTimeStr: string = undefined;
    mode:string = undefined;
    static getTableColumns() {
        return [];
    }
    toModel(json): any {
        super.toModel(json);
        this.updatedTimeStr = dateFormat(this.updateTime) || '';
        this.mode = FilterFileType(this.templateUrl);
        return super.toModel(json);
    }

    toService() {
        const data: any = super.toService();
        return data;
    }
}

export class EngineTemplateQueryModel extends QueryPageModel {
    // @QueryControl({
    //     label: '报告类型',
    //     type: QueryControlType.SELECT,
    //     options: [{ name: '不限', value: '' }, ..._.map(TemplateType, item => ({ name: item.label, value: item.value }))],
    //     span: 6
    // })
    // type: boolean | string = '';
    // @QueryControl({
    //     label: '模板格式',
    //     type: QueryControlType.SELECT,
    //     options: [{ name: '不限', value: '' },
    //         { name: 'Word', value: 'Word' },
    //         { name: 'PPT', value: 'PPT' },
    //         { name: 'Excel', value: 'Excel' },
    //         { name: 'Markdown', value: 'Markdown' },
    //         { name: 'H5', value: 'H5' }],
    //     span: 6
    // })
    // mode: boolean | string = '';
    @QueryControl({
        label: '报告名称',
        type: QueryControlType.TEXT,
        span: 6
    })
    templateName: string = '';
    @QueryControl({
        label: '是否启用',
        type: QueryControlType.SELECT,
        options: [{ name: '不限', value: '' },
            { name: '启用', value: 'true' },
            { name: '禁用', value: 'false' }],
        span: 6
    })
    enabled: boolean | string = '';
    @QueryControl({
        label: '更新时间',
        type: QueryControlType.SELECT_DATE,
        showTime: false,
        span: 8
    })
    // dateRange: Array<Moment> = JTL.CONSTANT.LAST_7_DAY();
    dateRange: Array<Moment> = [];
    toService() {
        // return { params: this.templateName };
        const data = super.getParams();
        if (data.params.dateRange && data.params.dateRange.length === 2) {
            data.params.startTime = this.dateRange[0]?.startOf('day').format(DEFAULT_FORMAT);
            data.params.endTime = moment(getEndTimeByIntervalType(this.dateRange[1], PeriodTypeEnum.DAY)).format(DEFAULT_FORMAT);
            // this.dateRange[1].clone().add(1, 'day').startOf('day').toDate().getTime();
            // this.dateRange[0].startOf('day').toDate().getTime();
        }
        if (data.params.enabled) {
            data.params.enabled = data.params.enabled === 'true' ? true : false;
        }
        Reflect.deleteProperty(data.params, 'dateRange');
        return data;
    }
}

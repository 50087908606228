



















import { Component, Ref } from 'vue-property-decorator';
import TableComponent from '@common-src/mixins/table-component';
import { FormControlType } from '@common-src/model/form-control';
const isUrl = (url: string) => {
    const reg = /^https?:\/\//;
    return reg.test(url);
};
@Component
export default class TemplateSyncPreviewDialog extends TableComponent<any, any> {
    dialogVisible: boolean = false;
    fileExtension:string = null;
    remoteUrl: string = null;
    isshow: boolean = false;
    created() {}

    dialogOpen(filePath) {
        this.fileExtension = '';
        const fileExtension = filePath.match(/\.([^.]+)$/);
        if (fileExtension) {
            this.fileExtension = fileExtension[1]; // 输出文件后缀
        } else {
            console.log('无法获取文件后缀');
        }
        this.isshow = true;
        this.dialogVisible = true;
        this.listLoading = true;
        const url = isUrl(filePath)
            ? `${decodeURIComponent(filePath)}`
            : `${window.location.protocol}//${window.location.host}${decodeURIComponent(filePath)}`;
        this.remoteUrl = url;
    }
    dialogOK() {
        this.dialogVisible = false;
    }

    dialogClose(): void {
        this.dialogVisible = false;
    }
}

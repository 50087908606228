export const Dateformat = [
    { value: 'YYYY-MM-DD', label: 'YYYY-MM-DD' },
    { value: 'YYYY年M月DD', label: 'YYYY年MM月DD' },
    { value: 'YYYY年MM月', label: 'YYYY年MM月' },
    { value: 'YYYY-MM', label: 'YYYY-MM' },
    { value: 'YYYY年', label: 'YYYY年' }
];
export const TimeOptions: Array<{value: string, label: string}> = [
    { value: '1', label: '当天' },
    { value: '-1', label: '前天' },
    { value: '7', label: '本周' },
    { value: '-7', label: '上周' },
    { value: '-6', label: '近七天（不含今天）' },
    { value: '30', label: '本月' },
    { value: '-30', label: '上月' },
    { value: '-23', label: '近三十天（不含今天）' },
    { value: '120', label: '本季度' },
    { value: '-120', label: '上季度' },
    { value: '1201', label: '近三个月（不含本月）' },
    { value: '1210', label: '本年' },
    { value: '-360', label: '全年' }
];
export const TemplateType = [
    { label: '运营报告', value: 'yunying' },
    { label: '运维报告', value: 'yunwei' },
    { label: '能耗报告', value: 'nenghao' },
    { label: '机电运行报告', value: 'jidiang' }
];
export const DocType = [
    { label: 'Word', value: 'Word' },
    { label: 'PPT', value: 'PPT' },
    { label: 'Excel', value: 'Excel' },
    { label: 'Markdown', value: 'Markdown' },
    { label: 'PDF', value: 'PDF' },
    { label: 'H5', value: 'H5' }
];
export const EnableType = [
    { label: '不限', value: '' },
    { label: '启用', value: true },
    { label: '禁用', value: false }
];
export const MessageChannle = [
    { label: '站内信', value: 'site' },
    { label: '服务号', value: 'service' },
    { label: '企业微信', value: 'wechat' },
    { label: '飞书', value: 'feishu' },
    { label: '钉钉', value: 'Ding' },
    { label: '邮箱', value: 'email' }
];
export const FixType = [
    { label: '站内信', value: 'prefix' },
    { label: '服务号', value: 'suffix' }
];
export const ChannelList = [
    { label: '消息中心：站内信', value: 'MESSAGE_CENTER' },
    { label: '微信服务号：企业服务号', value: 'WE_CHAT_PUBLIC_ACCOUNT' },
    { label: '飞书机器人：企业飞书机器人', value: 'FEI_SHU_ROBOT' },
    { label: '飞书应用：飞书应用机器人', value: 'FEI_SHU_APPLICATION_ROBOT' },
    { label: '钉钉机器人：企业钉钉机器人', value: 'DING_TALK_ROBOT' },
    { label: '邮件：企业邮件', value: 'EMAIL' }
];
export const FilterFileType = (url: string): string => {
    const suffix = url.match(/[^.]+$/)[0];
    let format = '';
    switch (suffix) {
        case 'docx':
        case 'doc':
            format = 'Word';
            break;
        case 'xls':
        case 'xlsx':
            format = 'Excel';
            break;
        case 'ppt':
        case 'pptx':
            format = 'PPT';
            break;
        case 'html':
        case 'htm':
            format = 'H5';
            break;
        case 'Markdown':
        case 'md':
            format = 'Markdown';
            break;
        case 'pdf':
            format = 'PDF';
            break;
        case 'txt':
            format = 'TXT';
            break;
        default:
            break;
    }
    return format;
};

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      staticClass: "form-edit-component-large",
      attrs: { title: "预览", visible: _vm.dialogVisible },
      on: { cancel: _vm.dialogClose },
    },
    [
      _c(
        "div",
        { staticClass: "things-sync-preview-dialog" },
        [
          (_vm.fileExtension === "xls" || _vm.fileExtension === "xlsx") &&
          _vm.dialogVisible
            ? _c("vue-office-excel", { attrs: { src: _vm.remoteUrl } })
            : _vm._e(),
          (_vm.fileExtension === "doc" || _vm.fileExtension === "docx") &&
          _vm.dialogVisible
            ? _c("vue-office-docx", { attrs: { src: _vm.remoteUrl } })
            : _vm._e(),
          _vm.fileExtension === "pdf" && _vm.dialogVisible
            ? _c("vue-office-pdf", { attrs: { src: _vm.remoteUrl } })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "jtl-button",
            { attrs: { "click-prop": _vm.dialogClose.bind(this) } },
            [_vm._v("取消")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import BaseCRUDQService from '@common-src/service3/base-crudq.service';
import http from '@common-src/service3/http/axios';
import { get, post, del, download } from '@common-src/service/request';
import { EngineTemplateModel, EngineTemplateQueryModel } from '@/entity-model/engine-template-entity';

export default class EngineTemplateService extends BaseCRUDQService<EngineTemplateModel, EngineTemplateQueryModel> {
    constructor(baseUrl = EngineTemplateModel.baseUrl) {
        super(baseUrl, EngineTemplateModel);
    }
    async created(model): Promise<any> {
        const url = `${this.baseUrl}/add`;
        const params = model;
        const res = await http.request(
            {
                url,
                method: 'POST',
                params
            },
            { modelType: null }
        );
        return res;
    }
    // 获取数据集分组
    static async getDatasetGroupList(pid: string = ''):Promise<any> {
        const url = `/bdmm-vista/api/datasetGroup/list`;
        const res = await http.request<any>(
            {
                url,
                method: 'GET'
            }
        );
        return res;
    }
    // 获取数据集数据
    static async getDatasetList(params):Promise<any> {
        const url = `/bdmm-vista/api/dataset/list`;
        const res = await http.request<any>(
            {
                url,
                method: 'POST',
                params: {
                    params,
                    page: 1,
                    limit: 10000
                }
            },
            { isTransformRequestResult: true }
        );
        const result = (res.rows || res).map(item => {
            return {
                key: item.id,
                title: item.datasetName,
                children: [],
                ...item
            };
        });
        return result;
    }
    // 获取数据集字段
    static async getDatasetField(datasetId: string):Promise<any> {
        const url = `/bdmm-vista/api/datasetField/${datasetId}`;
        const res = await http.request<any>(
            {
                url,
                method: 'POST'
            }
        );
        return res;
    }
    // 获取报告定时模板
    static async getReportScheduleTemplate(templateId: string = ''):Promise<any> {
        const url = `${REPORT_EBGINE_REQUEST_PATH}/report/schedule/${templateId}`;
        const res = await http.request<any>(
            {
                url,
                method: 'GET'
            }
        );
        return res;
    }
    // 获取模版高级配置
    static async getReportTemplateScheduleSetting(templateId: string = ''):Promise<any> {
        const url = `${REPORT_EBGINE_REQUEST_PATH}/report/template/${templateId}/setting`;
        const res = await http.request<any>(
            {
                url,
                method: 'GET'
            }
        );
        return res;
    }
    // 保存模版高级配置
    static async saveReportTemplateScheduleSetting(params: any):Promise<any> {
        const url = `${REPORT_EBGINE_REQUEST_PATH}/report/template/${params.templateId}/setting`;
        const res = await http.request<any>(
            {
                url,
                method: 'POST',
                params
            }
        );
        return res;
    }
    // 保存报告定时模板
    static async saveReportScheduleTemplate(params):Promise<any> {
        const url = `${REPORT_EBGINE_REQUEST_PATH}/report/schedule/save`;
        const res = await http.request<any>(
            {
                url,
                method: 'POST',
                params
            }
        );
        return res;
    }
    // 启用、禁用
    static async templateEngineEnabled(value: {id: string, enabled: boolean}):Promise<any> {
        const url = `${EngineTemplateModel.baseUrl}/${value.id}/${value.enabled ? 'enable' : 'disable'}`;
        const res = await http.request<any>(
            {
                url,
                method: 'POST'
            }
        );
        return res;
    }
    // 生成报告
    static async reportGenerate(params):Promise<any> {
        const url = `${REPORT_EBGINE_REQUEST_PATH}/report/generate`;
        const res = await http.request<any>(
            {
                url,
                method: 'POST',
                params
            }
        );
        return res;
    }
    // 预览报告
    static async previewReportGenerate(params):Promise<any> {
        const url = `${REPORT_EBGINE_REQUEST_PATH}/report/preview`;
        const res = await http.request<any>(
            {
                url,
                method: 'POST',
                params
            }
        );
        return res;
    }
    // 解析模版内标签列表
    static async getTemplateElementsInfo(templateId: string):Promise<any> {
        const url = `${REPORT_EBGINE_REQUEST_PATH}/report/template/${templateId}/templateElements`;
        const res = await http.request<any>(
            {
                url,
                method: 'GET'
            }
        );
        return res;
    }
    // static async importDeviceTypesByJsonFile(importModel:any) {
    //     const url = `${EngineTemplateModel.baseUrl}/template/import`;
    //     const params = importModel.toService();
    //     const res = await post(url, params, { headers: { 'content-type': 'multipart/form-data' }, timeout: 600000 });
    //     return res;
    // }

    // static async exportTemplateEngine(ids:any) {
    //     const url = `${EngineTemplateModel.baseUrl}/template/export`;
    //     const res = await post(url, ids);
    //     return res;
    // }
    static async importTemplateEngine(file):Promise<any> {
        const url = `${EngineTemplateModel.baseUrl}/import/template`;
        const formData = new FormData();
        formData.append('file', file);
        const res = await post(url, formData);
        return res;
    }

    static async exportTemplateEngine(ids: Array<string>) {
        const url = `${EngineTemplateModel.baseUrl}/export/template`;
        const res = await download(url, ids, 'post');
        return res;
    }
}
